@import 'common/scss/variables';

.pageWrapper {
    background: $white;
    padding: 0 25px;
    margin-bottom: 3rem;

    @media only screen and (min-width: 768px) {
        padding: 0 50px;
    }
}
